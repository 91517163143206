/* global YT */

window.dataLayer = window.dataLayer || [];
var players = [];
function findObjectIndexById(haystack, key, needle) {
  for (var i = 0; i < haystack.length; i++) {
    if (haystack[i][key] == needle) {
      return i;
    }
  }
  return null;
}
function EmptyEventListeners(player) {
  var key = Object.keys(player).find(function (key) {
    return PropertiesCheck(player[key]) != undefined;
  });
  player[key] = {};
}
function PropertiesCheck(property) {
  if (property) return Object.keys(property).find(function (key) {
    return key == 'onStateChange';
  });else return undefined;
}
function actionModale(event) {
  var target = event.target;
  var id;
  var indexPlayer;
  if (event.eventName == 'modalOpen') {
    id = target.querySelector('iframe').id;
    indexPlayer = findObjectIndexById(players, 'id', id);
    if (!players[indexPlayer].player.isPlayerInModal) {
      EmptyEventListeners(players[indexPlayer].player);
      players[indexPlayer].player.addEventListener('onStateChange', 'onPlayerStateChange');
      players[indexPlayer].player.isPlayerInModal = true;
    }
  } else {
    id = target.querySelector('iframe').id;
    indexPlayer = findObjectIndexById(players, 'id', id);
    if (players[indexPlayer].player.pauseVideo) {
      players[indexPlayer].player.pauseVideo();
    }
  }
}
function setupVideoYoutube() {
  document.querySelectorAll('iframe[id]').forEach(function (e) {
    var player = new YT.Player(e.id);
    player.dataId = e.id;
    players.push({
      'player': player,
      'id': player.dataId
    });
  });
}
document.addEventListener('modalOpen', actionModale);
document.addEventListener('modalClose', actionModale);